const csMessages = {
  cs: {
    orders: {
      order: {
        externalOrderNumber: {
          label: 'Vaše číslo požadavku',
        },
      },
    },
    reactMuiTables: {
      filter: {
        actions: {
          resetFilter: 'resetovat filtr',
        },
      },
    },
    homePage: {
      title: 'Úvodní stránka',
      gotoShopping: 'Nakupovat',
      downloadPriceLists: {
        title: 'Ceníky ke stažení',
        waitingMessage: 'Ceníky ke stažení budou k dipozici po jejich vygenerovaní.',
        downloadProblem: 'Nezdařilo se získlat informace k stažení ceníků.',
      },
    },

    cataloguePage: {},
    customerProfileCreditNotesPage: {
      title: 'Dobropisy',
      filter: {
        number: 'Číslo dobropisu',
      },
    },
    customerProfileCreditNoteDetailPage: {
      subjectList: {
        title: 'Položky',
      },
    },
    customerProfileDocumentsPage: {
      title: 'Dokumenty',
    },
    customerProfileShoppingListsPage: {
      title: 'Nákupní seznamy',
    },
    customerProfileOrdersConfirmationPage: {
      title: 'Ke schválení/Zamítnutí',
    },
    customerProfileInvoicesPage: {
      title: 'Faktury',
      filter: {
        number: 'Číslo faktury',
      },
    },
    customerProfileInvoiceDetailPage: {
      subjectList: {
        title: 'Položky faktury',
      },
    },
    customerProfileOrderDetailPage: {
      actions: {
        import: 'Vložit do košíku',
      },
      phase: {
        label: 'Stav',
      },
      subjectList: {
        title: 'Položky objednávky',
      },
      totalWithTax: {
        label: 'Celkem s DPH',
      },
    },
    customerProfileOrdersPage: {
      title: 'Objednávky',
      addUndeliveredItemsToCart: 'Přidat nedodané položky do košíku',
    },
    customerProfilePage: {
      title: 'Uživatelský profil',
      changePassword: {
        confirmPassword: 'Zadaná hesla se neshodují',
        passwordStrongEnough: [
          'Heslo není dostatečně silné. ',
          'Heslo musí obsahovat alespoň 8 znaků, z nichž je alespoň jeden velké písmeno, malé písmeno a číslo.',
          'Heslo nesmí obsahovat mezery.',
        ],
      },
    },
    shoppingCart: {
      messages: {
        cartManipulations: {
          allRemoved: 'Všechny položky košíku byly odstraněny.',
          itemAdded: 'Do košíku {number, plural, one {byla přidána položka} other {byly přidány položky}}.',
          itemRemoved: 'Z košíku {number, plural, one {byla odstraněna položka} other {byly odstraněny položky}}.',
        },
      },
    },
    app: {
      products: {
        stockQuantityInfo: {
          onStock: 'skladem',
          notOnStock: 'není skladem',
          notOnStockKnownWhenExpected: 'očekávané datum naskladnění {timing}',
          pcs: '{qty, plural, one {{qtyF} kus skladem} few {{qtyF} kusy skladem} other {{qtyF} kusů skladem}}',
        },
      },

      pickDeliveryPlace: {
        label: 'Místo doručení',
        helperText: 'Vyberte provozovnu do které bude objednávka doručena',
        requiredError: 'Vyberte prosím provozovnu',
      },

      pickShipping: {
        label: 'Typ doručení',
        helperText: 'Vyberte typ doručení, který má být využit k doručení objednávky',
        requiredError: 'Vyberte prosím typ doručení',
      },
    },
  },
};

export default csMessages;
